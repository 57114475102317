import React from 'react'
import * as styles from '../../assets/styles.module.scss'
import DigitalOcean from '../../assets/vectors/PartnerBadges_blue.svg'

export default () => (
    <section className={styles.Services}>
        <div className={styles.Centered}>
            <header>
                <h1>What we do</h1>

                <p className={styles.Subtitle}>
                    We are here to help you foster long-lasting relationships 
                    with your current and future customers by prodiving a unique
                    set of skills that adds value with every single detail we touch.
                </p>
            </header>

            <div className={styles.Block}>
                <h2>Development</h2>

                <ul>
                    <li>Web applications</li>
                    <li>Mobile applications</li>
                    <li>Front-end Engineering (Web / Mobile)</li>
                    <li>Website Design / Development</li>
                    <li>eCommerce</li>
                    <li>Legacy systems modernization</li>
                    <li>Chatbots</li>
                </ul>
            </div>

            <div className={styles.Block}>
                <h2>Infraestructure</h2>

                <ul>
                    <li>Resource Optimization</li>
                    <li>DevOps</li>
                    <li>Kubernetes</li>
                    <li>Deployment Management</li>
                    <li>Monitoring Services</li>
                    <li>Uptime Management</li>
                    <li>Server Architecture</li>
                    <li><DigitalOcean /></li>
                </ul>
            </div>

            <div className={styles.Block}>
                <h2>Strategy & Innovation</h2>

                <ul>
                    <li>Conversion rate optimization</li>
                    <li>Retention rate optimization</li>
                    <li>Value Proposition Design</li>
                    <li>SEO Strategy</li>
                    <li>Product Strategy</li>
                    <li>Service Design</li>
                    <li>User Research</li>
                    <li>Design Training</li>
                    <li>Innovation Roadmapping</li>
                </ul>
            </div>

            <div className={styles.Block}>
                <h2>Design Studio</h2>

                <ul>
                    <li>User Interface Design</li>
                    <li>User Experience Design</li>
                    <li>Prototyping</li>
                    <li>UX Writing</li>
                    <li>Interaction Design</li>
                    <li>Design Systems</li>
                </ul>
            </div>
        </div>
    </section>
)